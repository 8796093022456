import { Box, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, dividerClasses, IconButton, Link, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { Fragment } from 'react';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, useResourceContext, useResourceDefinitionContext, ArrayInput, SimpleFormIterator, useSaveContext, useNotify, FormDataConsumer, useSimpleFormIteratorItem, FunctionField, useRecordContext, useRefresh, Form, DateInput, SimpleList, fetchUtils, ListContext, ListContextProvider, useList } from 'react-admin';
import {
  useListContext,
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { TextField as MTextField, Button as MButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from 'tss-react/mui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Stack } from '@mui/system';
import { useMutation, useQuery } from 'react-query';
import { httpClient, httpClientReturnFile } from '../dataProvider';
import { apiUrl, dealWuLiuCom, isDevelopment } from '../../global';
import qs from 'qs';
import { useForm, useFormContext } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ShareIcon from '@mui/icons-material/Share';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EditIcon from '@mui/icons-material/Edit';

{/* 
  import { DF_WdgjOrderList, DF_WdgjOrderCreate, DF_WdgjOrderEdit, DF_WdgjOrderShow } from './components/DF_WdgjOrder';

  <Resource name="CMSDF_WdgjOrder" list={DF_WdgjOrderList} create={DF_WdgjOrderCreate} edit={DF_WdgjOrderEdit} show={DF_WdgjOrderShow} />
  <Resource name="CMSDF_WdgjOrder" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles()((theme) => {
  return {
    imageField: {
      '& img': { width: 60 }
    },
  };
});

const useTxtData = {
  page: {
    tableName: '结算单',
  },
};

// const QDList = () => {
//   const resource = useResourceContext();
//   const { data, isLoading, isSuccess, isError, error } = useQuery('QDList', () => {
//     return httpClient(`${apiUrl}/${resource}/GetQDItems`, { method: 'POST' });
//   }, { refetchOnWindowFocus: false });

//   if (isLoading) return null;
//   if (isSuccess) {
//     let { Code, Result } = JSON.parse(data.body) as any;
//     return <SelectInput label="渠道" source="ShopName" choices={Result} />
//   }

//   return null;
// }

//分页列表页面
export const DF_Admin_JSWdgjOrderList = () => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  // 筛选器模块
  // const Filters = () => {
  //   return (
  //     <Filter>
  //       <TextInput label="ID" source="id" alwaysOn />
  //     </Filter>
  //   );
  // };
  const MobileFilters = [
    <TextInput label="关键词" source="Key" alwaysOn resettable />
  ];
  const Filters = (props: any) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
    // if (isSmall) return null;
    if (props.context === "button") return null;
    const onSubmit = (values: any) => { if (Object.keys(values).length > 0) { setFilters(values, displayedFilters); } else { setFilters({}, displayedFilters); } refetch(); };
    const resetFilter = () => { setFilters({}, displayedFilters); };
    return (
      <div>
        <Form onSubmit={onSubmit} defaultValues={filterValues}>
          <Stack direction={'row'} alignItems={'baseline'} spacing={1.5}>
            {/* <QDList /> */}
            <TextInput label="关键词" source="Key" alwaysOn resettable />
            <DateInput label="创建时间 开始" source="StartTime" />
            <div> - </div>
            <DateInput label="结束" source="EndTime" />
            <BooleanInput label="含取消订单" source="AndCancel" defaultValue={false} />
            <MButton variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>搜索</MButton>
            <MButton variant="outlined" onClick={resetFilter} startIcon={<ClearAllIcon />}>重置</MButton>
          </Stack>
        </Form>
      </div>
    );
  };

  //批量操作模块
  const AssetBulkActionButtons = () => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序"  /> */}
        {/* <BulkDeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" mutationMode='undoable' /> */}
      </Fragment>
    );
  };

  const { mutate: confirmJS } = useMutation(
    (params: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/ConfirmJS`, {
          method: 'POST',
          body: qs.stringify(params),
          headers: requestHeaders,
        }).then(({ json }) => {
          console.log('json', json);
          if (json.Code == 1) {
            notify(`成功`, { type: 'success' });
            refresh();
            resolve(json.Result);
          } else {
            notify(`失败`, { type: 'error' });
            resolve(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );

  const { mutate: backJS } = useMutation(
    (params: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/BackJS`, {
          method: 'POST',
          body: qs.stringify(params),
          headers: requestHeaders,
        }).then(({ json }) => {
          console.log('json', json);
          if (json.Code == 1) {
            notify(`成功`, { type: 'success' });
            refresh();
            resolve(json.Result);
          } else {
            notify(`失败`, { type: 'error' });
            resolve(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );

  const { mutate: finishJS } = useMutation(
    (params: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/FinishJS`, {
          method: 'POST',
          body: qs.stringify(params),
          headers: requestHeaders,
        }).then(({ json }) => {
          console.log('json', json);
          if (json.Code == 1) {
            notify(`成功`, { type: 'success' });
            refresh();
            resolve(json.Result);
          } else {
            notify(`失败`, { type: 'error' });
            resolve(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );

  const ShareBtn = ({ ShopName, JSNumber, TotalPrice, ShareSign }: any) =>
    <MButton onClick={() => {
      let url = `https://qd.scxiniu.com/#/sharejs/${ShareSign}`;
      let msg = `${ShopName}-结算单[${JSNumber}] ¥${parseFloat(TotalPrice).toFixed(2)} \r\n${url}`;
      copy(msg);
      notify('复制成功\r\n' + msg, { type: 'success' });
    }} >分享结算单</MButton>

  const ExportBtn = ({ JSNumber, id }: any) =>
    <MButton onClick={async () => {
      const url = await httpClientReturnFile(`${apiUrl}/${resource}/ExportJS?id=` + id, { method: 'POST' });
      // Creating the hyperlink and auto click it to start the download
      let link = document.createElement('a');
      link.href = url.toString();
      link.download = `结算单【${JSNumber}】.xlsx`;
      link.click();
    }} >下载结算单</MButton>

  //行右侧功能按钮
  const RowAction = () => {
    const record = useRecordContext();
    let { id, JSNumber, TotalPrice, ShopName, ShareSign, JSStatus } = record;
    if (JSStatus === '创建中')
      return (
        <div style={{ textAlign: 'right' }}>
          <Stack alignItems={'flex-end'}>
            <MButton onClick={() => confirmJS({ id })} >确认结算单</MButton>
            <ShareBtn ShopName={ShopName} JSNumber={JSNumber} TotalPrice={TotalPrice} ShareSign={ShareSign} />
            <ExportBtn id={id} JSNumber={JSNumber} />
            <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" />
          </Stack>
        </div>
      );
    if (JSStatus === '结算中')
      return (
        <div style={{ textAlign: 'right' }}>
          <Stack alignItems={'flex-end'}>
            <MButton sx={{ color: (theme: any) => theme.palette.error.main }} onClick={() => backJS({ id })} >回退到创建中</MButton>
            <ShareBtn ShopName={ShopName} JSNumber={JSNumber} TotalPrice={TotalPrice} ShareSign={ShareSign} />
            <ExportBtn id={id} JSNumber={JSNumber} />
            <MButton onClick={() => {
              if (window.confirm("确定已经收到款了？")) {
                finishJS({ id })
              }
            }}>已收款</MButton>
          </Stack>
        </div>
      );
    if (JSStatus === '已结算')
      return (
        <div style={{ textAlign: 'right' }}>
          <Stack alignItems={'flex-end'}>
            <ShareBtn ShopName={ShopName} JSNumber={JSNumber} TotalPrice={TotalPrice} ShareSign={ShareSign} />
            <ExportBtn id={id} JSNumber={JSNumber} />
          </Stack>
        </div>
      );
    return null;
  }

  const resource = useResourceContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const { classes } = useStyles();

  const { mutate: setJS, isLoading } = useMutation(
    (params: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/Admin/CMSAdminDF_WdgjOrder/SetJS`, {
          method: 'POST',
          body: qs.stringify(params),
          headers: requestHeaders,
        }).then(({ json }) => {
          console.log('json', json);
          if (json.Code == 1) {
            notify(`设置成功`, { type: 'success' });
            refresh();
            resolve(json.Result);
          } else {
            notify(`设置失败 ${json.Message}`, { type: 'error' });
            resolve(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );

  const PostPanel = () => {
    const record = useRecordContext();
    const { JSStatus } = record;
    record.orders = record.orders.map((order: any, __index__: number) => ({ ...order, __index__ }))
    const listContext = useList({ data: record.orders });
    return (
      <Stack sx={{
        mx: 10, backgroundColor: '#f6f6f6',
        '& .RaDatagrid-headerCell': {
          backgroundColor: 'transparent'
        }
      }}>
        <ListContextProvider value={listContext}>
          <Datagrid bulkActionButtons={false}>
            <FunctionField label='订单' render={({ __index__, WdgjOrderNumber, CreateTime, __OrderSource }: any) => {
              const daoru = __OrderSource === 'f_wdgj';
              return (
                <>
                  <div>
                    <Chip size='small' sx={{ mr: 1 }} label={__index__ + 1} />
                    {daoru && <Chip size='small' color='warning' label={'导'} />}
                  </div>
                  <div>{WdgjOrderNumber}</div>
                  <div>{moment(CreateTime).format("MM-DD HH:mm")}</div>
                </>
              )
            }} />
            <FunctionField label='金额' render={({ STotalPrice, SYunFei, JS_TuiKuan }: any) => {
              return (
                <>
                  <div>供价 ¥{parseFloat(STotalPrice).toFixed(2)}</div>
                  {(SYunFei ?? 0) != 0 && <div>运费 ¥{SYunFei}</div>}
                  {JS_TuiKuan === true && <Chip size='small' label={`已退款`} color='error' />}
                </>
              )
            }} />
            <FunctionField label='地址' render={({ ReceiverName, ReceiverMobile, Province, City, Area, Street, Address }: any) => {
              return (
                <>
                  <div>{ReceiverName} {ReceiverMobile}</div>
                  <div>{Province}{City}{Area}{Street}</div>
                  <div>{Address}</div>
                </>
              )
            }} />
            <FunctionField label='货品' render={({ ProList }: any) => {
              // let proItems = Pros.split('\r\n');
              return (
                <Stack spacing={0.3} alignItems={'flex-start'}>
                  {ProList.map(({ df_Price, Code, Title, Count, df_YunFei, IsFlaw, BrandName }: any, index: number) => <Chip label={
                    <>
                      {IsFlaw === true ? <span style={{ backgroundColor: 'red', color: '#FFF', padding: '0 1px' }}>旧</span> : null} {`¥${df_Price}${df_YunFei ?? 0 != 0 ? `|${df_YunFei}` : ''} ${BrandName ?? ''} ${Code} ${Title}*${Count}`}
                    </>
                  } size="small" sx={{
                    height: 'auto',
                    '& .MuiChip-label': {
                      display: 'block',
                      whiteSpace: 'normal',
                    },
                  }} />)}
                </Stack>
              )
            }} />
            <FunctionField label='状态' render={({ WdgjOrderStatus }: any) => {
              return (
                <>
                  <div>{WdgjOrderStatus}</div>
                </>
              )
            }} />
            <FunctionField label='物流' render={({ WuLiuCompany, WuLiuNumber }: any) => {
              return (
                <>
                  <div>{dealWuLiuCom(WuLiuCompany)}</div>
                  <div>{WuLiuNumber}</div>
                </>
              )
            }} />

            <FunctionField label='' render={({ id }: any) => {
              if (JSStatus === '创建中')
                return (
                  <IconButton disabled={isLoading} onClick={() => setJS({ id })} color='error'>
                    {isLoading ? <ScheduleIcon /> : <ClearIcon />}</IconButton>
                )
            }} />
          </Datagrid>
        </ListContextProvider>
      </Stack>
    );
  };

  const EditMemo = ({ id, Remark }: any) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const [remark, setRemark] = React.useState(Remark ?? '');

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const submit = () => {
      // const { buyerMemo, sellerMemo } = data;
      // console.log('Data', { id, buyerMemo, sellerMemo })
      UpdateMemo({});
    }

    const { mutate: UpdateMemo, isLoading } = useMutation(
      ({ }: any) => {
        return new Promise<any>((resolve, reject) => {
          var requestHeaders = new Headers();
          requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
          httpClient(`${apiUrl}/${resource}/UpdateMemo`, {
            method: 'POST',
            body: qs.stringify({ id, remark }),
            headers: requestHeaders,
          }).then(({ json }) => {
            console.log(json);
            if (json.Code == 1) {
              notify('修改成功', { type: 'success' });
              refresh();
              handleClose();
              resolve(json);
            } else {
              notify(json.Message, { type: 'error' });
              refresh();
              reject(json.Message);
            }
          }).catch((e: any) => {
            let { status, body, name } = e;
            if (status === 401) {
              localStorage.removeItem('token');
              redirect(`/Login`);
            }
            console.log('e', status, body, name);
            reject(e);
          })
        });
      }
    );
    return (
      <>
        <Stack direction={'row'} alignItems={'center'} spacing={1}>
          <EditIcon onClick={handleClickOpen} fontSize='small' sx={{ color: '#FD5052' }} />
          <div style={{ maxWidth: 200, wordWrap: 'break-word' }}>{Remark}</div>
        </Stack>
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='xs'>
          <DialogTitle>修改备注</DialogTitle>
          <DialogContent>
            <Stack>
              <MTextField multiline minRows={1} maxRows={2} label="备注" variant="outlined" value={remark} onChange={(e) => setRemark(e.target.value)} />
            </Stack>
          </DialogContent>
          <DialogActions>
            <MButton color='warning' onClick={handleClose}>取消</MButton>
            <MButton onClick={submit} color='primary' disabled={isLoading}>{isLoading ? '保存中...' : '保存'}</MButton>
          </DialogActions>
        </Dialog>

      </>
    )
  }

  return (
    <List
      title={useTxtData.page.tableName + '列表'}
      sort={{ field: 'CreateTime', order: 'DESC' }}
      // filters={isSmall ? MobileFilters : <Filters />}
      exporter={false}
      sx={{ "& .MuiToolbar-root .MuiToolbar-root": { height: 0, minHeight: 0 } }}
    >
      {/* {!isSmall ? */}
      <Datagrid bulkActionButtons={false} expand={<PostPanel />}>
        <FunctionField label='渠道' render={({ ShopName }: any) => {
          return (<div>{ShopName}</div>)
        }} />
        <FunctionField label='结算号' render={({ JSNumber, orders }: any) => {
          return (<div>{JSNumber} ({orders.length}单)</div>)
        }} />
        <FunctionField label='结算状态' render={({ JSStatus, has_tuikuan }: any) => {
          return (<div>{JSStatus} <span style={{ color: 'red' }}>{has_tuikuan == true ? '(含退款)' : ''}</span></div>)
        }} />
        <FunctionField label='总价' render={({ TotalPrice }: any) => {
          return (<div>¥{parseFloat(TotalPrice).toFixed(2)}</div>)
        }} />
        <FunctionField label='备注' render={({ id, Remark }: any) => {
          return (<div>
            <EditMemo id={id} Remark={Remark} />
          </div>)
        }} />
        <FunctionField label='更新时间' render={({ LastUpdateTime, CreateTime }: any) => {
          return (
            <>
              <div><span style={{ borderRadius: 2, padding: 1, backgroundColor: 'green', color: '#FFF' }}>更</span> {moment(LastUpdateTime).format("MM-DD HH:mm")}</div>
            </>
          )
        }} />
        <FunctionField label='创建时间' render={({ LastUpdateTime, CreateTime }: any) => {
          return (
            <>
              <div><span style={{ borderRadius: 2, padding: 1, backgroundColor: 'green', color: '#FFF' }}>创</span> {moment(CreateTime).format("MM-DD HH:mm")}</div>
            </>
          )
        }} />
        <RowAction />
      </Datagrid>
      {/* } */}
    </List>
  );
};