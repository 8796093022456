import { Box, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, dividerClasses, IconButton, Link, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from '@mui/material';
import * as React from 'react';
import { Fragment } from 'react';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, useResourceContext, useResourceDefinitionContext, ArrayInput, SimpleFormIterator, useSaveContext, useNotify, FormDataConsumer, useSimpleFormIteratorItem, FunctionField, useRecordContext, useRefresh, Form, DateInput, SimpleList, EditBase } from 'react-admin';
import {
  useListContext,
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
  CreateButton,
} from 'react-admin';
import { FormControl as MFormControl, InputLabel as MInputLabel, TextField as MTextField, Button as MButton, Select as MSelect, MenuItem as MMenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { makeStyles } from 'tss-react/mui';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Stack } from '@mui/system';
import { useQuery, useMutation } from 'react-query';
import { httpClient } from '../dataProvider';
import { apiUrl, dealWuLiuCom, GetWdgjOrderStatusCodeColor, isDevelopment } from '../../global';
import qs from 'qs';
import { useForm, useFormContext, useFormState } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ShareIcon from '@mui/icons-material/Share';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import { orange } from '@mui/material/colors';
import { usePermissions } from 'react-admin';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import EditIcon from '@mui/icons-material/Edit';

{/* 
  import { DF_WdgjOrderList, DF_WdgjOrderCreate, DF_WdgjOrderEdit, DF_WdgjOrderShow } from './components/DF_WdgjOrder';

  <Resource name="CMSDF_WdgjOrder" list={DF_WdgjOrderList} create={DF_WdgjOrderCreate} edit={DF_WdgjOrderEdit} show={DF_WdgjOrderShow} />
  <Resource name="CMSDF_WdgjOrder" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles()((theme) => {
  return {
    imageField: {
      '& img': { width: 60 }
    },
  };
});

const useTxtData = {
  page: {
    tableName: '销售单',
  },
  table: {
    id: 'id',
    AdminID: 'AdminID',
    ReceiverName: '收件人',
    ReceiverMobile: '手机',
    Province: '省',
    City: '市',
    Area: '区',
    Street: '街道',
    Address: '详细地址',
    Remark: '订单备注(会写入Erp客户备注)',
    CreateTime: '提交时间',
    ImportWdgjOrder: '接收订单',
    ImportWdgjOrderTime: '接收订单时间',
    WdgjOrderNumber: '订单号码',
    WdgjOrderStatus: '订单状态',
    GetWuLiu: '物流同步',
    GetWuLiuTime: '物流同步时间',
    WuLiuCompany: '物流公司',
    WuLiuNumber: '物流号码',
    ShopName: '渠道',
    Tag: '标签',
  }
};

export const QDList = (props: any = {}) => {
  const resource = `Admin/CMSAdminDF_WdgjOrder`; //useResourceContext();
  const { data, isLoading, isSuccess, isError, error } = useQuery('QDList', () => {
    return httpClient(`${apiUrl}/${resource}/GetQDItems`, { method: 'POST' });
  }, { refetchOnWindowFocus: false });

  if (isLoading) return null;
  if (isSuccess) {
    let { Code, Result } = JSON.parse(data.body) as any;
    return <SelectInput {...props} label="渠道" source="ShopName" choices={Result} helperText={false} />
  }

  return null;
}
const TagList = () => {
  return (
    <SelectInput label={useTxtData.table.Tag} source="Tag" choices={[
      // { id: '闲鱼', name: '闲鱼' },
      // { id: '米哈玩具', name: '米哈玩具' },
      { id: 'Lucas玩具沈倩雯', name: 'Lucas玩具沈倩雯' },
      { id: '柠檬妈绘本童品馆', name: '柠檬妈绘本童品馆' },
      { id: '安徽陈伟', name: '安徽陈伟' },
    ]} />
  );
}

//分页列表页面
export const DF_WdgjOrderList = () => {
  const { permissions } = usePermissions();
  let isAdmin = permissions === 'Admin';
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  // 筛选器模块
  // const Filters = () => {
  //   return (
  //     <Filter>
  //       <TextInput label="ID" source="id" alwaysOn />
  //     </Filter>
  //   );
  // };

  const Filters = (props: any) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
    if (props.context === "button") return null;
    const onSubmit = (values: any) => { if (Object.keys(values).length > 0) { setFilters(values, displayedFilters); } else { setFilters({}, displayedFilters); } refetch(); };
    const resetFilter = () => {
      // console.log("filter", displayedFilters, filterValues);
      const { ShopName } = filterValues;
      setFilters({ ShopName }, displayedFilters);
    };
    return (
      <div>
        <Form onSubmit={onSubmit} defaultValues={filterValues}>
          <Stack direction={isSmall ? 'column' : 'row'} alignItems={isSmall ? 'stretch' : 'baseline'} spacing={isSmall ? 0 : 1.5}>
            {isAdmin && <QDList />}
            <TextInput label="关键词" source="Key" alwaysOn resettable helperText={false} />
            <DateInput label="创建时间 开始" source="StartTime" helperText={false} />
            {!isSmall && <div> - </div>}
            <DateInput label="结束" source="EndTime" helperText={false} />
            <BooleanInput label="含取消订单" source="AndCancel" defaultValue={false} helperText={false} />
            <MButton variant="contained" color="primary" type="submit" startIcon={<SearchIcon />} sx={{ mb: isSmall ? 1 : 0 }}>搜索</MButton>
            <MButton variant="outlined" onClick={resetFilter} startIcon={<ClearAllIcon />}>重置</MButton>
          </Stack>
        </Form>
      </div>
    );
  };
  const _copyItem = ({ index, WuLiuNumber, WuLiuCompany, ReceiverName, ReceiverMobile, Province, City, Area, Street, Address, Pros, WdgjOrderNumber, GetWuLiu, ShareSign }: any) => {
    // const wl = WuLiuNumber ? `\r\n${dealWuLiuCom(WuLiuCompany) ?? ""} ${WuLiuNumber ?? ""}` : '';
    // return `【${index + 1}】${ReceiverName} ${ReceiverMobile}\r\n${Province + City + Area + Street + Address}\r\n${Pros}${wl}`;
    let txt = index == null ? `` : `【${index + 1}】`;
    txt += `订单号码：${WdgjOrderNumber}\r\n`;
    txt += `收货信息：${ReceiverName} ${ReceiverMobile}\r\n`;
    txt += `收货地址：${Province}${City}${Area}${Street}${Address}\r\n`;
    txt += `货品摘要：${Pros}\r\n`;

    if (GetWuLiu) {
      txt += `快递信息：${dealWuLiuCom(WuLiuCompany)} ${WuLiuNumber}\r\n`;
    }

    // let url = `跟踪链接：https://qd.scxiniu.com/#/shareorder/${ShareSign}`;
    var url = `跟踪链接：https://qd.scxiniu.com/shareorder2.html?Sign=${ShareSign}`;
    return txt + url;
  }

  const ListActions = () => {
    const { data } = useListContext();
    return (
      <TopToolbar>
        {/* <Button label="复制当页物流信息" onClick={() => {
          var wlArr = [...data].reverse().map(({ id, ReceiverName, ReceiverMobile, Pros, Province, City, Area, Street, Address, WuLiuCompany, WuLiuNumber, WdgjOrderNumber, GetWuLiu, ShareSign }, index: number) => {
            return _copyItem({ index, WuLiuNumber, WuLiuCompany, ReceiverName, ReceiverMobile, Province, City, Area, Street, Address, Pros, WdgjOrderNumber, GetWuLiu, ShareSign });
          })
          copy(wlArr.join('\r\n\r\n'));
          notify('复制成功\r\n', { type: 'success' });
        }} /> */}
        <CreateButton />
      </TopToolbar>
    )
  };

  const PostBulkActionButtons = () => {
    const { selectedIds, data } = useListContext();
    return (
      <Stack direction={'row'} alignItems={'center'} alignSelf={'center'}>
        <Button label="复制选中物流信息" onClick={() => {
          // console.log('selectedIds', selectedIds)
          let choose_wlArr: any[] = []; var index = 0;
          [...data].reverse().forEach(({ id, ReceiverName, ReceiverMobile, Pros, Province, City, Area, Street, Address, WuLiuCompany, WuLiuNumber, WdgjOrderNumber, GetWuLiu, ShareSign }) => {
            if (selectedIds.indexOf(id) >= 0) {
              choose_wlArr.push(_copyItem({ index, WuLiuNumber, WuLiuCompany, ReceiverName, ReceiverMobile, Province, City, Area, Street, Address, Pros, WdgjOrderNumber, GetWuLiu, ShareSign }));
              index++;
            }
          });
          copy(choose_wlArr.join('\r\n\r\n'));
          notify('复制选中成功\r\n', { type: 'success' });
        }} />
      </Stack>)
  };

  //批量操作模块
  const AssetBulkActionButtons = () => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序"  /> */}
        {/* <BulkDeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" mutationMode='undoable' /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = () => {
    const record = useRecordContext();
    let { ImportWdgjOrder, WdgjOrderNumber } = record;
    return (
      <div style={{ textAlign: 'right' }}>
        {/* <EditButton /><br /> */}
        {/* <ShowButton /><br /> */}
        {!ImportWdgjOrder ?
          <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" />
          :
          <>
            <IconButton aria-label="刷新">
              <RefreshIcon color='primary' fontSize="inherit" onClick={() => RefreshWdgjOrder(WdgjOrderNumber)} />
            </IconButton>
            <IconButton aria-label="分享">
              <ShareIcon color='warning' fontSize="inherit" onClick={() => ShareWdgjOrder(record)} />
            </IconButton>
          </>
        }
      </div>
    );
  }

  const resource = useResourceContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();

  const ShareWdgjOrder = ({ WuLiuCompany, WuLiuNumber, GetWuLiu, ShareSign, WdgjOrderNumber, ReceiverName, ReceiverMobile, Province, City, Area, Street, Address, Pros }: any) => {
    let text = _copyItem({ index: null, WuLiuNumber, WuLiuCompany, ReceiverName, ReceiverMobile, Province, City, Area, Street, Address, Pros, WdgjOrderNumber, GetWuLiu, ShareSign });
    copy(text);
    notify('复制成功\r\n' + text, { type: 'success' });
  }

  const { mutate: RefreshWdgjOrder, isLoading } = useMutation(
    (WdgjOrderNumber: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/RefreshWdgjOrder`, {
          method: 'POST',
          body: qs.stringify({ WdgjOrderNumber }),
          headers: requestHeaders,
        }).then(({ json }: any) => {
          console.log(json);
          if (json.Code == 1) {
            notify('刷新成功', { type: 'success' });
            refresh();
            resolve(json);
          } else {
            notify(json.Message, { type: 'error' });
            reject(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );


  const { classes } = useStyles();

  const MItem = ({ Title, Value, ...Props }: any) => {
    console.log('Value', Props)
    let VType = typeof (Value);
    return (
      <Stack {...Props} direction={'row'} alignItems={'center'} style={{ fontSize: 12 }}>
        <div style={{ color: '#666' }}>{Title}</div>
        <span style={{ flex: 1 }}></span>
        {VType == 'function' ?
          Value()
          :
          Value
        }
      </Stack>
    )
  }

  const _redirectSearch = ({ ReceiverMobile }: any) => {

    // const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
    // console.log('123', useListContext())
    // const ShopName = "";
    // redirect(`${resource}?filter={"ShopName":"${ShopName}","Key":"${ReceiverMobile}"}`);
  }

  const CCount = ({ count, tipColor, ReceiverMobile }: any) => {
    const { filterValues } = useListContext();
    const ShopName = filterValues.ShopName ?? '';
    // console.log('filterValues', filterValues)
    // console.log('ShopName', ShopName)
    return <>
      {
        count > 1 && <Chip sx={{ ml: 0.5, userSelect: 'none' }} size='small' color={tipColor === 'w' ? 'warning' : 'error'} label={count}
          onClick={() => {
            redirect(`${resource}?filter={"ShopName":"${ShopName}","Key":"${ReceiverMobile}"}`);
          }}
        />
      }
    </>
  }

  return (
    <List
      actions={<ListActions />}
      title={useTxtData.page.tableName + '列表'}
      perPage={25}
      sort={{ field: 'CreateTime', order: 'DESC' }}
      filters={<Filters />}
      exporter={false}
      sx={{ "& .MuiToolbar-root .MuiToolbar-root": { height: 0, minHeight: 0 }, "& .MuiToolbar-root > div:first-child": isSmall ? { width: '100%' } : {} }}
    >
      {!isSmall ?
        <Datagrid bulkActionButtons={<PostBulkActionButtons />} isRowSelectable={({ ImportWdgjOrder }: any) => ImportWdgjOrder === true}>
          {/* <TextField source="id" /> */}
          {/* <TextField label="名称" source="Name" /> */}
          {/* <TextField label="排序" source="OrderNum" />  */}
          {/* <TextField label={useTxtData.table.id} source="id" sortable={false} /> */}
          {/* <TextField label={useTxtData.table.AdminID} source="AdminID" /> */}

          <FunctionField label='时间/备注' render={({ __index__, ShopName, Tag, CreateTime, Remark, __OrderSource }: any) => {
            // console.log('record', record)
            const daoru = __OrderSource === 'f_wdgj';
            return (
              <>
                <div><Chip size='small' sx={{ mr: 1 }} label={__index__ + 1} /></div>
                <div>{ShopName && <ShopNameView ShopName={ShopName} />}</div>
                <div>
                  {Tag && <Chip size='small' color='warning' label={Tag} />}
                  {daoru && <span style={{ backgroundColor: 'red', color: '#FFF', padding: '0 1px' }}>导</span>}
                </div>
                {CreateTime && <div>{moment(CreateTime).format("MM-DD HH:mm")}</div>}
                {Remark && <Typography sx={{ color: '#FD5052' }} variant='caption'>{`备注: ${Remark}`}</Typography>}
              </>
            )
          }} />
          {/* <TextField label={useTxtData.table.CreateTime} source="CreateTime" /> */}

          {/* <TextField label={useTxtData.table.ReceiverName} source="ReceiverName" />
          <TextField label={useTxtData.table.ReceiverMobile} source="ReceiverMobile" />
          <TextField label={useTxtData.table.Province} source="Province" />
          <TextField label={useTxtData.table.City} source="City" />
          <TextField label={useTxtData.table.Area} source="Area" />
          <TextField label={useTxtData.table.Street} source="Street" />
          <TextField label={useTxtData.table.Address} source="Address" /> */}
          <FunctionField label='收件人信息/备注' render={({ id, BuyCounts, ReceiverName, ReceiverMobile, Province, City, Area, Street, Address, WdgjOrderRemark }: any) => {
            // console.log('record', record)
            // console.log('123', useListContext());

            let { count, tipColor } = BuyCounts;
            return (
              <Typography variant='subtitle2'>
                <div>{`${ReceiverName} ${ReceiverMobile}`}
                  <CCount count={count} tipColor={tipColor} ReceiverMobile={ReceiverMobile} />
                </div>
                <div>{`${Province} ${City} ${Area} ${Street}`}</div>
                <div>{`${Address}`}</div>
                {isAdmin &&
                  <Stack direction={'row'} alignItems={'center'}>
                    <EditMemo id={id} />
                    {WdgjOrderRemark && <Typography sx={{ color: '#FD5052' }} variant='caption'>{`Erp备注: ${WdgjOrderRemark}`}</Typography>}
                  </Stack>
                }
              </Typography>
            )
          }} />

          <FunctionField label='货品信息' render={({ id, ProList: proItems }: any) => {
            let canReRreateOrder = true;
            proItems.forEach(({ ProJSON }: any) => { if (ProJSON !== 'usable') canReRreateOrder = false })
            return (
              <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
                <Stack spacing={0.3} alignItems={'flex-start'}>
                  {proItems.map(({ id, Code, Title, Count, df_Price, IsFlaw }: any, index: number) =>
                    <Stack direction={'row'} alignItems={'flex-start'}>
                      {/* {IsFlaw === true && <Chip label={`旧`} color='error' size="small" sx={{ height: 'auto', '& .MuiChip-label': { display: 'block', whiteSpace: 'normal', }, }} />} */}
                      <Chip label={<>{IsFlaw === true ? <span style={{ backgroundColor: 'red', color: '#FFF', padding: '0 2px', borderRadius: 2 }}>旧</span> : null} {`${Code} ${Title} * ${Count}`}</>} size="small" sx={{ height: 'auto', '& .MuiChip-label': { display: 'block', whiteSpace: 'normal', }, }} />
                    </Stack>
                  )}
                </Stack>
                {isAdmin && canReRreateOrder === true &&
                  <IconButton size='small' onClick={() => {
                    redirect(`/Admin/CMSDF_WdgjOrder/create?gId=${id}`);
                  }} >
                    <AttachFileIcon fontSize='small' sx={{ color: 'gray' }} />
                  </IconButton>
                }
              </Stack>
            )
          }} />

          <FunctionField label='系统状态' render={({ ImportWdgjError, ImportWdgjErrorMsg, ImportWdgjOrder, ImportWdgjOrderTime }: any) => {
            // console.log('record', record)
            if (ImportWdgjError) {
              return (
                <>
                  <div style={{ color: 'red' }}>创建异常</div>
                  <div style={{ maxWidth: 100, wordBreak: 'break-all' }}>{ImportWdgjErrorMsg}</div>
                </>
              )
            }
            else
              return (
                <>
                  {/* {ImportWdgjOrderTime && <div>{moment(ImportWdgjOrderTime).format("MM-DD HH:mm")}</div>} */}
                  <div>{ImportWdgjOrder ? <Chip sx={{ backgroundColor: '#6EC4AE', color: '#FFF' }} label="已入系统" size="small" /> : ''}</div>
                </>
              )
          }} />

          <FunctionField label='订单状态' render={({ WdgjOrderStatusCode, WdgjOrderStatus, WdgjOrderNumber }: any) => {
            var color = GetWdgjOrderStatusCodeColor(WdgjOrderStatusCode);
            return (
              <>
                {WdgjOrderNumber &&
                  <>
                    <div>{WdgjOrderNumber}</div>
                    <Stack direction={'row'} alignItems={'center'}>
                      <Chip label={WdgjOrderStatus} size="small" sx={{ color: '#FFF', backgroundColor: color }} />
                    </Stack>
                  </>
                }
              </>
            )
          }} />

          {/* <FunctionField label='物流状态' render={({ ImportWdgjOrder, GetWuLiu, GetWuLiuTime }: any) => {
            return (
              <>
                {ImportWdgjOrder &&
                  <>
                    {GetWuLiuTime && <div>{moment(GetWuLiuTime).format("MM-DD HH:mm")}</div>}
                    <div>{GetWuLiu ? <Chip sx={{ backgroundColor: '#14492B', color: '#FFF' }} label="已出单号" size="small" /> : ''}</div>
                  </>
                }
              </>
            )
          }} /> */}

          <FunctionField label='物流信息' render={({ ImportWdgjOrder, GetWuLiu, GetWuLiuTime, WuLiuCompany, WuLiuNumber }: any) => {
            return (
              <>
                {/* {
                  ImportWdgjOrder &&
                  <>
                    {GetWuLiuTime && <div>{moment(GetWuLiuTime).format("MM-DD HH:mm")}</div>}
                    <div>{GetWuLiu ? <Chip sx={{ backgroundColor: '#14492B', color: '#FFF' }} label="已出单号" size="small" /> : ''}</div>
                  </>
                } */}
                {GetWuLiu &&
                  <span onClick={() => {
                    let _m = `${WuLiuNumber}`;
                    copy(_m); notify(_m, { type: 'success' });
                  }}>
                    <div>{dealWuLiuCom(WuLiuCompany)}</div>
                    <Chip label={WuLiuNumber} size="small" />
                  </span>
                }
              </>
            )
          }} />

          <RowAction />
        </Datagrid>
        :
        <SimpleList
          linkType={false}
          sx={{ "& .MuiButtonBase-root": { p: 0, m: 0 } }}
          primaryText={({
            id,
            CreateTime, Remark,
            BuyCounts, ReceiverName, ReceiverMobile, Province, City, Area, Street, Address,
            Pros,
            ImportWdgjError, ImportWdgjErrorMsg, ImportWdgjOrder, ImportWdgjOrderTime,
            WdgjOrderStatusCode, WdgjOrderStatus, WdgjOrderNumber, WdgjOrderRemark,
            GetWuLiu, GetWuLiuTime,
            WuLiuCompany, WuLiuNumber,
            Tag, ShopName, __OrderSource,
            ProList
          }: any) => {
            const daoru = __OrderSource === 'f_wdgj';
            let proItems = Pros.split('\r\n');

            let { count, tipColor } = BuyCounts;

            let canReRreateOrder = true;
            ProList.forEach(({ ProJSON }: any) => { if (ProJSON !== 'usable') canReRreateOrder = false })

            return (
              <Paper sx={{ p: 1 }} elevation={2}>
                {ShopName &&
                  <MItem Title={'渠道'} Value={() => <ShopNameView ShopName={ShopName} />} />
                }
                {Tag &&
                  <MItem Title={'标签'} Value={() => <Chip size='small' color='warning' label={Tag} />} />
                }
                {daoru &&
                  <MItem Title={''} Value={() => <span style={{ backgroundColor: 'red', color: '#FFF', padding: '0 1px' }}>导</span>} />
                }
                <MItem Title={'创建时间'} Value={CreateTime} />
                {Remark && <MItem Title={'备注'} Value={<span style={{ color: 'red' }}>{Remark}</span>} />}
                {/* <MItem Title={'收货信息'} Value={`${ReceiverName} ${ReceiverMobile}`} /> */}
                <MItem Title={'收货信息'} Value={() => {
                  return (
                    <>{`${ReceiverName} ${ReceiverMobile}`}
                      <CCount count={count} tipColor={tipColor} ReceiverMobile={ReceiverMobile} />
                    </>
                  )
                }} />
                <MItem Title={'收货地址'} Value={`${Province} ${City} ${Area} ${Street}`} />
                <MItem Title={''} Value={`${Address}`} />
                <MItem sx={{ py: 1 }} Title={'货品信息'} Value={() => {
                  return (
                    <Stack direction={'row'} alignItems={'center'}>
                      <Stack alignItems={'flex-end'}>
                        {ProList.map(({ id, Code, Title, Count, df_Price, IsFlaw }: any, index: number) => <div>
                          {IsFlaw === true && <><span style={{ backgroundColor: 'red', color: '#FFF', padding: '0 2px', borderRadius: 2 }}>旧</span>&nbsp;</>}
                          {`${Code} ${Title} * ${Count}`}
                        </div>)}
                      </Stack>
                      {isAdmin && canReRreateOrder === true &&
                        <IconButton size='small' onClick={() => {
                          redirect(`/Admin/CMSDF_WdgjOrder/create?gId=${id}`);
                        }} >
                          <AttachFileIcon fontSize='small' sx={{ color: 'gray' }} />
                        </IconButton>
                      }
                    </Stack>
                  )
                }} />
                <MItem Title={'系统状态'} Value={() => {
                  if (ImportWdgjError) {
                    return <div style={{ wordBreak: 'break-all' }}>创建异常:{ImportWdgjErrorMsg}</div>
                  } else {
                    return <Stack direction={'row'} alignItems={'center'} spacing={1}>
                      {ImportWdgjOrderTime && <div>{ImportWdgjOrderTime}</div>}
                      <div>{ImportWdgjOrder ? <Chip sx={{ backgroundColor: '#6EC4AE', color: '#FFF' }} label="已入系统" size="small" /> : ''}</div>
                    </Stack>
                  }
                }} />
                <MItem sx={{ py: 1 }} Title={'订单状态'} Value={() => {
                  var color = GetWdgjOrderStatusCodeColor(WdgjOrderStatusCode);
                  return (
                    <>
                      {WdgjOrderNumber &&
                        <>
                          <div>{WdgjOrderNumber}</div>
                          <Stack direction={'row'} alignItems={'center'} sx={{ pl: 1 }}>
                            <Chip label={WdgjOrderStatus} size="small" sx={{ color: '#FFF', backgroundColor: color }} />
                          </Stack>
                        </>
                      }
                    </>
                  )
                }} />
                {isAdmin && <MItem Title={'Erp备注'} Value={() => <>
                  <EditMemo id={id} />
                  <span style={{ color: 'red' }}>{WdgjOrderRemark}</span>
                </>} />}
                <MItem sx={{}} Title={'物流状态'} Value={() => {
                  return (
                    <>
                      {ImportWdgjOrder &&
                        <>
                          {GetWuLiuTime && <div>{GetWuLiuTime}</div>}
                          <Stack sx={{ pl: 1 }}>{GetWuLiu ? <Chip sx={{ backgroundColor: '#14492B', color: '#FFF' }} label="已出单号" size="small" /> : ''}</Stack>
                        </>
                      }
                    </>
                  )
                }} />
                <MItem sx={{ py: 1 }} Title={'物流信息'} Value={() => {
                  return (
                    <>
                      {GetWuLiu &&
                        <Stack direction={'row'} alignItems={'center'} onClick={() => {
                          let _m = `${WuLiuNumber}`;
                          copy(_m); notify(_m, { type: 'success' });
                        }}>
                          <div>{dealWuLiuCom(WuLiuCompany)}</div>
                          <Chip sx={{ ml: 1 }} label={WuLiuNumber} size="small" />
                        </Stack>
                      }
                    </>
                  )
                }} />
                <Divider sx={{ my: 1 }} />
                <FunctionField render={(record: any) =>
                  <Stack direction={'row'} justifyContent={'flex-end'}>
                    {!ImportWdgjOrder ?
                      <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" />
                      :
                      <>
                        <IconButton aria-label="刷新" style={{ marginRight: 8 }}>
                          <RefreshIcon color='primary' fontSize="inherit" onClick={() => RefreshWdgjOrder(WdgjOrderNumber)} />
                        </IconButton>
                        <IconButton aria-label="分享" style={{ marginRight: 8 }}>
                          <ShareIcon color='warning' fontSize="inherit" onClick={() => ShareWdgjOrder(record)} />
                        </IconButton>
                      </>
                    }
                  </Stack>
                } />
              </Paper>
            )
          }}
        />
      }
    </List>
  );
};

export const EditMemo = ({ id }: any) => {
  const { permissions } = usePermissions();
  let isAdmin = permissions === 'Admin';
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [remark, setRemark] = React.useState('');
  const [buyerMemo, setBuyerMemo] = React.useState('');
  const [sellerMemo, setSellerMemo] = React.useState('');

  // const [loadMemoCount, setLoadMemoCount] = React.useState(0);
  const [loadMemo, setLoadMemo] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    // setLoadMemoCount(loadMemoCount + 1);
    _loadMemo();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const submit = () => {
    console.log('data', id, remark, buyerMemo, sellerMemo)
    // const { buyerMemo, sellerMemo } = data;
    // console.log('Data', { id, buyerMemo, sellerMemo })
    UpdateMemo({ id, remark, buyerMemo, sellerMemo });
  }

  // React.useEffect(() => {
  //   _loadMemo();
  // }, [loadMemoCount]);

  const _loadMemo = () => {
    setLoadMemo(true);
    var requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
    httpClient(`${apiUrl}/${'Admin/CMSDF_WdgjOrder'}/LoadMemo`, {
      method: 'POST',
      body: qs.stringify({ id }),
      headers: requestHeaders,
    }).then(({ json }: any) => {
      console.log(json);
      if (json.Code == 1) {
        setLoadMemo(false);
        const { remark, buyerMemo, sellerMemo } = json.Result;
        setRemark(remark);
        setBuyerMemo(buyerMemo);
        setSellerMemo(sellerMemo);
      } else {
        notify(json.Message, { type: 'error' });
      }
    }).catch((e: any) => {
      setLoadMemo(false);
      let { status, body, name } = e;
      if (status === 401) {
        localStorage.removeItem('token');
        redirect(`/Login`);
      }
      console.log('e', status, body, name);
    })
  }

  const { mutate: UpdateMemo, isLoading } = useMutation(
    ({ id, remark, buyerMemo, sellerMemo }: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${'Admin/CMSDF_WdgjOrder'}/UpdateMemo`, {
          method: 'POST',
          body: qs.stringify({ id, remark, buyerMemo, sellerMemo }),
          headers: requestHeaders,
        }).then(({ json }: any) => {
          console.log(json);
          if (json.Code == 1) {
            notify('修改成功', { type: 'success' });
            refresh();
            handleClose();
            resolve(json);
          } else {
            notify(json.Message, { type: 'error' });
            refresh();
            reject(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );
  return (
    <>
      <EditIcon onClick={handleClickOpen} fontSize='small' sx={{ color: '#FD5052' }} />
      {isAdmin &&
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='xs'>
          <DialogTitle>修改网店管家订单备注 {loadMemo ? '数据加载中.' : null}</DialogTitle>
          <DialogContent>
            <Stack>
              <MTextField disabled={loadMemo} multiline minRows={1} maxRows={2} label="备注" variant="outlined" value={remark} onChange={(e) => setRemark(e.target.value)} />
              <MTextField disabled={loadMemo} multiline minRows={2} maxRows={3} label="客户备注" variant="outlined" value={buyerMemo} onChange={(e) => setBuyerMemo(e.target.value)} />
              <MTextField disabled={loadMemo} multiline minRows={2} maxRows={3} label="客服备注" variant="outlined" value={sellerMemo} onChange={(e) => setSellerMemo(e.target.value)} />
            </Stack>
          </DialogContent>
          <DialogActions>
            <MButton color='warning' onClick={handleClose}>取消</MButton>
            <MButton onClick={submit} color='primary' disabled={isLoading}>{isLoading ? '保存中...' : '保存'}</MButton>
          </DialogActions>
        </Dialog>
      }
    </>
  )
}

const ShopNameView = ({ ShopName }: any) => {
  const { permissions } = usePermissions();
  let isAdmin = permissions === 'Admin';
  const resource = useResourceContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    if (isAdmin) setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const submit = (data: any) => {
    console.log('data', data)
    const { id, ShopName, Tag } = data;
    console.log('Data', { id, ShopName, Tag })
    UpdateSTMsg({ id, ShopName, Tag });
  }
  const { mutate: UpdateSTMsg, isLoading } = useMutation(
    ({ id, ShopName, Tag }: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/UpdateSTMsg`, {
          method: 'POST',
          body: qs.stringify({ id, ShopName, Tag }),
          headers: requestHeaders,
        }).then(({ json }: any) => {
          console.log(json);
          if (json.Code == 1) {
            notify('修改成功', { type: 'success' });
            refresh();
            handleClose();
            resolve(json);
          } else {
            notify(json.Message, { type: 'error' });
            reject(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );
  return (
    <>
      <div onClick={handleClickOpen}>
        <Chip size='small' label={ShopName} />
      </div>
      {isAdmin &&
        <Dialog open={open} onClose={handleClose}>
          <Form onSubmit={submit}>
            <DialogTitle>修改 渠道/标签</DialogTitle>
            <DialogContent>
              <Stack>
                <QDList />
                <TagList />
              </Stack>
            </DialogContent>
            <DialogActions>
              <MButton color='warning' onClick={handleClose}>取消</MButton>
              <SaveButton label={isLoading ? '保存中...' : '保存'} disabled={isLoading} />
            </DialogActions>
          </Form>
        </Dialog>
      }
    </>
  )
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const DF_WdgjOrderShow = () => {

  const ShowActions = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
        <EditButton />
      </TopToolbar>
    );
  };

  const classes = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.AdminID} source="AdminID" />
        <TextField label={useTxtData.table.ReceiverName} source="ReceiverName" />
        <TextField label={useTxtData.table.ReceiverMobile} source="ReceiverMobile" />
        <TextField label={useTxtData.table.Province} source="Province" />
        <TextField label={useTxtData.table.City} source="City" />
        <TextField label={useTxtData.table.Area} source="Area" />
        <TextField label={useTxtData.table.Street} source="Street" />
        <TextField label={useTxtData.table.Address} source="Address" />
        <TextField label={useTxtData.table.Remark} source="Remark" />
        <TextField label={useTxtData.table.CreateTime} source="CreateTime" />
        <TextField label={useTxtData.table.ImportWdgjOrder} source="ImportWdgjOrder" />
        <TextField label={useTxtData.table.ImportWdgjOrderTime} source="ImportWdgjOrderTime" />
        <TextField label={useTxtData.table.WdgjOrderNumber} source="WdgjOrderNumber" />
        <TextField label={useTxtData.table.WdgjOrderStatus} source="WdgjOrderStatus" />
        <TextField label={useTxtData.table.GetWuLiu} source="GetWuLiu" />
        <TextField label={useTxtData.table.GetWuLiuTime} source="GetWuLiuTime" />
        <TextField label={useTxtData.table.WuLiuCompany} source="WuLiuCompany" />
        <TextField label={useTxtData.table.WuLiuNumber} source="WuLiuNumber" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const DF_WdgjOrderEdit = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();

  const PostEditActions = () => {
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };

  const PostEditToolbar = () => {
    return (
      <Toolbar>
        <SaveButton />
      </Toolbar>
    );
  };

  return (
    <Edit redirect={`/${resource}`} title={'编辑' + useTxtData.page.tableName} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const DF_WdgjOrderCreate = (props: any) => {
  const resource = useResourceContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const PostCreateActions = () => {
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text" >
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };

  const PostCreateToolbar = () => {
    const { reset, resetField, handleSubmit, setValue, getValues, } = useFormContext();
    return (
      <Toolbar>
        <SaveButton alwaysEnable label="创建订单" />
        <SaveButton alwaysEnable type='button' label="创建&继续" mutationOptions={{
          onSuccess: () => {
            notify('创建成功', { type: 'success' });
            // reset(); 
            resetField('ReceiverName'); resetField('ReceiverMobile');
            resetField('Province'); resetField('City'); resetField('Area'); resetField('Street');
            resetField('Address');
            // resetField('Remark');
          }
        }} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create redirect={`/${resource}`} title={'新增' + useTxtData.page.tableName} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props: any) => {
  const redirect = useRedirect();
  const resource = useResourceContext();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  let { Edit, Create, toolbar } = props;
  const { isLoading, permissions } = usePermissions();
  let isAdmin = permissions === 'Admin';

  return (
    <SimpleForm sx={{ alignItems: isSmall ? 'stretch' : 'flex-start' }} toolbar={toolbar} defaultValues={{ Pros: [{ GoodsId: '', SkuId: '', Code: '', Title: '', Count: 1 }] }}>
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <TextInput label={useTxtData.table.id} source="id" validate={[required()]} /> */}
      {/* <TextInput label={useTxtData.table.AdminID} source="AdminID" validate={[required()]} /> */}
      <Stack sx={{ alignItems: isSmall ? 'stretch' : 'flex-start', width: isSmall ? '100%' : 'auto' }}>
        <Stack sx={{ alignItems: isSmall ? 'stretch' : 'flex-start', gap: 2 }} direction={isSmall ? 'column' : 'row'}>
          <TextInput fullWidth={isSmall} label={useTxtData.table.ReceiverName} source="ReceiverName" validate={[required()]} />
          <TextInput fullWidth={isSmall} label={useTxtData.table.ReceiverMobile} source="ReceiverMobile" validate={[required(), regex(/^([0-9]{2,3}-)?(1[0-9]{10}(-[0-9]{4})?)$|^(([0-9]{3,4}-)?[0-9]{7,8})$/, '手机号码格式不对')]} />
          <JieXiAddress />
        </Stack>
        <Stack direction={isSmall ? 'column' : 'row'} sx={{ gap: 2 }}>
          <TextInput label={useTxtData.table.Province} source="Province" validate={[required()]} />
          <TextInput label={useTxtData.table.City} source="City" validate={[required()]} />
          <TextInput label={useTxtData.table.Area} source="Area" validate={[]} />
          <TextInput label={useTxtData.table.Street} source="Street" validate={[]} />
        </Stack>
        <TextInput fullWidth label={useTxtData.table.Address} source="Address" validate={[required()]} />

        <ArrayInput label={'货品明细'} source="Pros">
          <SimpleFormIterator fullWidth inline={!isSmall} disableClear getItemLabel={(index: any) => `#${index + 1}`}>
            {/* <TextInput label={'编号'} source="Code" helperText={false} validate={[required()]} /> */}
            <FormDataConsumer>
              {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
                getSource, // A function to get the valid source inside an ArrayInput
                ...rest
              }: any) => {
                return <FindProItem getSource={getSource} />
              }}
            </FormDataConsumer>
            <NumberInput fullWidth={isSmall} label={'数量'} source="Count" helperText={false} defaultValue={1} validate={[required()]} min={1} step={1} />
            {isAdmin && <BooleanInput sx={{ alignSelf: isSmall ? 'flex-start' : 'center' }} label={'瑕疵品'} source="IsFlaw" helperText={false} defaultValue={false} />}
          </SimpleFormIterator>
        </ArrayInput>

        <TextInput fullWidth label={useTxtData.table.Remark} source="Remark" validate={[]} />

        {isAdmin && <>
          {/* <BooleanInput label={'分配到残品仓'} source="inCanPin" defaultValue={false} helperText='默认进铁建仓' /> */}
          <Stack direction={'row'}>
            <QDList validate={[required()]} />
            <Stack sx={{ ml: 1 }}><TagList /></Stack>
          </Stack>
        </>
        }

        {(isAdmin && Create) &&
          <LoadGDatas />
        }

      </Stack>
      {/* <TextInput label={useTxtData.table.CreateTime} source="CreateTime" validate={[required()]} />
      <TextInput label={useTxtData.table.ImportWdgjOrder} source="ImportWdgjOrder" validate={[required()]} />
      <TextInput label={useTxtData.table.ImportWdgjOrderTime} source="ImportWdgjOrderTime" validate={[required()]} />
      <TextInput label={useTxtData.table.WdgjOrderNumber} source="WdgjOrderNumber" validate={[required()]} />
      <TextInput label={useTxtData.table.WdgjOrderStatus} source="WdgjOrderStatus" validate={[required()]} />
      <TextInput label={useTxtData.table.GetWuLiu} source="GetWuLiu" validate={[required()]} />
      <TextInput label={useTxtData.table.GetWuLiuTime} source="GetWuLiuTime" validate={[required()]} />
      <TextInput label={useTxtData.table.WuLiuCompany} source="WuLiuCompany" validate={[required()]} />
      <TextInput label={useTxtData.table.WuLiuNumber} source="WuLiuNumber" validate={[required()]} /> */}
    </SimpleForm>
  );
}

// const ShowShopNameView = () => {
//   const { data, isLoading, isSuccess, isError, error } = useQuery('ShopNameList', () => {
//     return httpClient(`${apiUrl}/${`Admin/CmsAdmin`}/GetShopNameList`, { method: 'GET' });
//   }, { refetchOnWindowFocus: false });

//   if (isLoading) return <div>Loading</div>;
//   if (isError) return <div>Load Err! {`${error}`}</div>;
//   console.log('data', data)
//   return (
//     <SelectInput label={useTxtData.table.ShopName} source="ShopName" choices={data?.json.Result} />
//   );
// }

const LoadGDatas = () => {
  const redirect = useRedirect();
  const resource = useResourceContext();
  const { setValue, getValues } = useFormContext();

  React.useEffect(() => {
    const url = window.location.href;
    if (url.indexOf("?gId=") > 0) {
      const gId = url.substring(url.indexOf("?gId=") + 5);
      console.log('gId', gId);
      // setTimeout(() => { loadGDatas({ gId }); }, 2000)
      loadGDatas({ gId });
    }
  }, [])

  const { mutate: loadGDatas } = useMutation(
    ({ gId }: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/LoadGHuoPin`, {
          method: 'POST',
          body: qs.stringify({ gId }),
          headers: requestHeaders,
        }).then(({ json }: any) => {
          console.log(json);
          if (json.Code == 1) {
            var newPros: any[] = [];
            const { dfOrder: { ShopName, Remark, Tag }, dfOrderPros }: any = json.Result;
            dfOrderPros.map(({ GoodsId, SkuId, Code, Title, ProJSON, Count, IsFlaw }: any) => {
              // let newPro = { GoodsId: SkuId, Code, Title, ProJSON, Count, IsFlaw }
              let newPro = { GoodsId, SkuId, Code, Title, ProJSON, Count, IsFlaw }
              console.log('newPro', newPro)
              newPros.push(newPro);
            })
            setValue(`Pros`, newPros);
            setValue(`Remark`, Remark);
            setValue(`ShopName`, ShopName);
            setValue(`Tag`, Tag);
            resolve(json.Result);
          } else {
            reject(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );
  return null;
}

const FindProItem = ({ key, getSource }: any) => {
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { index } = useSimpleFormIteratorItem();
  const notify = useNotify();
  const redirect = useRedirect();
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  const resource = useResourceContext();
  const { setValue, getValues } = useFormContext();
  const { register, handleSubmit, watch, setValue: setValue2, setFocus, formState: { errors } } = useForm();

  const [open, setOpen] = React.useState(false);
  const onSubmit = ({ Key }: any) => {
    Key = (Key ?? '').trim();
    if (Key.length < 1)
      notify(`搜索信息不能为空`, { type: 'warning' });
    else {
      console.log('Key', Key);
      setData([]);
      FindPro({ manyCondition: Key });
    }
  }

  const [data, setData] = React.useState<any[]>([]);
  const { mutate: FindPro, isLoading } = useMutation(
    (params: any) => {
      return new Promise<any>((resolve, reject) => {
        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/FindHuoPin`, {
          method: 'POST',
          body: qs.stringify(params),
          headers: requestHeaders,
        }).then(({ json }: any) => {
          console.log(json);
          if (json.Code == 1) {
            setData(json.Result.map((item: any) => ({ ...item, checked: false })));
            if (json.Result.length > 0) {
              const { manyCondition: key } = params;
              if (historyList.findIndex(t => t === key) === -1) {
                const _historyList = historyList.concat(key).slice(-30);
                setHistoryList(_historyList);
                localStorage.setItem(hisKey, _historyList.join(hisSplit));
              }
            }
            resolve(json.Result);
          } else {
            reject(json.Message);
          }
        }).catch((e: any) => {
          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })
      });
    }
  );

  const hisKey: string = 'historyList';
  const hisSplit: string = '@|@';
  const [historyList, setHistoryList] = React.useState<string[]>([]);
  React.useEffect(() => {
    var _his = localStorage.getItem(hisKey);
    if (_his != null) setHistoryList(_his.toString().split(hisSplit));
  }, []);

  const rowClick = (pro: any) => {
    let { goodsId, goodsNo, goodsName, skuId, skuNo, skuName, skuProperitesName, skuBarcode, unitName } = pro;
    let no = skuNo ?? goodsNo;
    let gg = skuName ?? skuProperitesName;
    if (gg == '默认规格') gg = '';
    let name = goodsName + (gg.length > 0 ? `[${gg}]` : ``)

    let oldPros = getValues("Pros");
    let oldPro = oldPros[index];
    console.log('oldPro', oldPro)
    let newPro = { ...oldPro, GoodsId: goodsId, SkuId: skuId, Code: no, Title: name, ProJSON: JSON.stringify(pro) }
    console.log('newPro', newPro)
    setValue(`Pros.${index}`, newPro);

    setOpen(false);
  }

  const rowClick2 = () => {
    let oldPros = getValues("Pros");
    let _index = 0;
    for (var i in data) {
      let pro = data[i];
      if (pro.checked) {
        let { checked, goodsId, goodsNo, goodsName, skuId, skuNo, skuName, skuProperitesName, skuBarcode, unitName } = pro;
        let no = skuNo ?? goodsNo;
        let gg = skuName ?? skuProperitesName;
        if (gg == '默认规格') gg = '';
        let name = goodsName + (gg.length > 0 ? `[${gg}]` : ``)

        let newPro = { GoodsId: goodsId, SkuId: skuId, Code: no, Title: name, ProJSON: JSON.stringify(pro) }
        let cindex = _index + index;
        // setValue(`Pros.${cindex}`, newPro);
        oldPros[cindex] = newPro;
        _index++;
      }
    }
    setValue(`Pros`, oldPros);
  }

  return (
    <>
      <TextInput fullWidth={isSmall} label={'编号'} source={getSource('Code')} helperText={false} validate={[required()]}
        InputProps={{ readOnly: true, }} onClick={() => {
          setOpen(true);
        }}
      />
      <TextInput fullWidth={isSmall} sx={{ flex: 1 }} label={'名称'} source={getSource("Title")} helperText={false}
        InputProps={{ readOnly: true, }} onClick={() => {
          setOpen(true);
        }}
      />
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={'sm'}>
        <Stack direction={'row'}>
          <DialogTitle>查询货品</DialogTitle>
          <Stack sx={{ flex: 1 }}></Stack>
          <MButton sx={{ alignSelf: 'center', color: ({ palette }) => palette.grey[600] }} onClick={() => setOpen(false)}><CloseIcon /></MButton>
        </Stack>
        <Stack direction={'row'} px={3} pb={2} flexWrap={'wrap'}>
          {historyList.map((k) => <Chip sx={{ mr: 1, mb: 1 }} size='small' label={k} onClick={() => {
            setValue2('Key', k);
            setFocus('Key');
            onSubmit({ Key: k })
          }} />)}
        </Stack>
        <DialogContent sx={{ pt: 0, }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <MTextField autoFocus sx={{ flex: 1 }} label="编号/名称/条码" variant="outlined" defaultValue={''} {...register("Key")} />
              <MButton disabled={isLoading} type='submit' variant="contained">{isLoading ? '查询中...' : '搜索'}</MButton>
            </Stack>
          </form>
          {(data && data.length > 0) ?
            <Table sx={{
              '& td.MuiTableCell-body,& th.MuiTableCell-head': { padding: 0.3 },
              '& tr.MuiTableRow-root:hover': { backgroundColor: '#eee' }
            }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>编号</TableCell>
                  <TableCell>货品</TableCell>
                  <TableCell>条码</TableCell>
                  <TableCell>单位</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((pro: any, row: number) => {
                  let { checked, isPackageGood, goodsId, goodsNo, goodsName, skuId, skuNo, skuName, skuProperitesName, skuBarcode, unitName } = pro;
                  let no = skuNo ?? goodsNo;
                  let gg = skuName ?? skuProperitesName;
                  if (gg == '默认规格') gg = '';
                  let name = goodsName + (gg.length > 0 ? `[${gg}]` : ``)
                  return (
                    <TableRow key={row} sx={{ cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        <Stack alignItems={'center'}>
                          <Chip label={row + 1} size="small" />
                          <Checkbox checked={checked} onClick={() => {
                            var _cdata = data.map((_item: any, _row: number) => {
                              if (_row !== row)
                                return { ..._item }
                              else
                                return { ..._item, checked: !checked }
                            });
                            setData(_cdata);
                          }} />
                        </Stack>
                      </TableCell>
                      <TableCell>{no}</TableCell>
                      <TableCell sx={{ color: (theme) => theme.palette.primary.main, }} onClick={() => rowClick(pro)}><span style={{ backgroundColor: '#ff4500', color: '#FFF', borderRadius: '2px' }}>{isPackageGood == 1 ? "组套" : ""}</span>{name}</TableCell>
                      <TableCell sx={{ wordBreak: 'break-all', wordWrap: 'break-word' }}>{skuBarcode}</TableCell>
                      <TableCell>{unitName}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            : <Typography variant='caption'>{isLoading ? '查询中...' : '暂无数据'}</Typography>
          }
        </DialogContent>
        <DialogActions>
          <MButton onClick={() => rowClick2()}>确定</MButton>
        </DialogActions>
      </Dialog>
    </>
  )
}

const JieXiAddress = () => {
  const redirect = useRedirect();
  const notify = useNotify();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const resource = useResourceContext();
  const { setValue } = useFormContext();
  const { register, resetField, handleSubmit, setValue: setValue2, watch, formState: { errors } } = useForm();

  const [open, setOpen] = React.useState(true);
  const onSubmit = ({ Address }: any) => {
    if (Address.length < 1)
      notify(`信息不能为空`, { type: 'warning' });
    else
      JieXiAddress({ Address })
  }
  const { mutate: JieXiAddress, isLoading } = useMutation(
    (params: any) => {
      return new Promise<any>((resolve, reject) => {

        var requestHeaders = new Headers();
        requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
        httpClient(`${apiUrl}/${resource}/JiXiAddress`, {
          method: 'POST',
          body: qs.stringify(params),
          headers: requestHeaders,
        }).then(({ json }: any) => {
          console.log('json', json);
          if (json.Code == 1) {
            notify(`地址解析成功`, { type: 'success' });
            setOpen(false);
            resetField('Address');

            let { receiverName, mobile, phone, state, city, district, town, address } = json.Result;
            setValue('ReceiverName', receiverName);
            setValue('ReceiverMobile', mobile || phone);
            setValue('Province', state);
            setValue('City', city);
            setValue('Area', district);
            setValue('Street', town);
            setValue('Address', address);
            resolve(json.Result);
          } else {
            notify(`地址解析失败`, { type: 'error' });
            resolve(json.Message);
          }
        }).catch((e: any) => {

          let { status, body, name } = e;
          if (status === 401) {
            localStorage.removeItem('token');
            redirect(`/Login`);
          }
          console.log('e', status, body, name);
          reject(e);
        })

      });
    }
  );

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     window.navigator.clipboard.readText().then((txt: any) => {
  //       console.log('剪贴板内容', txt);
  //       if (txt.length > 0) {
  //         setValue2("Address", txt);
  //       }
  //     })
  //   }, 50)
  // })

  // console.log('isSmall', isSmall)
  return (
    <Stack sx={{ alignSelf: 'center' }}>
      {/* <Button sx={{ alignSelf: 'center' }} label="解析地址" variant="text" onClick={() => setOpen(true)} /> */}
      {/* <MButton component={Link} sx={{ alignSelf: 'center' }} onClick={() => setOpen(true)}>解析地址</MButton> */}
      <Typography sx={{ cursor: 'pointer' }} color={'primary'} variant='subtitle2' onClick={() => setOpen(true)}>解析地址</Typography>

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={'sm'}>
        <DialogTitle>收货信息</DialogTitle>
        <DialogContent>
          <MTextField autoFocus fullWidth label="收货信息" variant="outlined" multiline minRows={3} defaultValue={
            isDevelopment ?
              // '万一 13907175291  湖北省 武汉市 武昌区  徐家棚街街道 徐家棚街街道徐家棚团结路新长江滨江城市花园7栋2单元1901号'
              ''
              :
              ''} {...register("Address")} />
        </DialogContent>
        <DialogActions>
          <MButton color='error' onClick={() => setOpen(false)}>取消</MButton>
          <LoadingButton sx={{ width: 100 }} loading={isLoading} loadingIndicator="解析中."
            onClick={handleSubmit(onSubmit)}>解析地址</LoadingButton>
        </DialogActions>
      </Dialog>
    </Stack >
  )
}