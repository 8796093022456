import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices, Form, useResourceContext, useNotify, FunctionField, useRefresh } from 'react-admin';
import {
  useListContext,
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import { useForm, useFormContext } from 'react-hook-form';
import { Button as MButton, Box, useMediaQuery, Stack } from '@mui/material';
import { PreviewImage, UpLoadFile } from './custom/UpLoadFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import ClearAllIcon from '@mui/icons-material/ClearAll';
//import ResetOrderNum from './FaPiaoLog_ResetOrderNum';

{/* 
  import { FaPiaoLogList, FaPiaoLogCreate, FaPiaoLogEdit, FaPiaoLogShow } from './components/FaPiaoLog';

  <Resource name="CMSFaPiaoLog" list={FaPiaoLogList} create={FaPiaoLogCreate} edit={FaPiaoLogEdit} show={FaPiaoLogShow} />
  <Resource name="CMSFaPiaoLog" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles()((theme) => {
  return {
    imageField: {
      '& img.RaImageField-image': { width: 60 }
    },
  };
});


const useTxtData = {
  page: {
    tableName: 'FaPiaoLog',
  },
  table: {
    id: 'id',
    sign: 'sign',
    fapiaoCode: '发票编号',
    fapiaoHaoMa: '发票号码',
    shudianHaoMa: '数电号码',
    xiaoshou_Number: '销售方',
    xiaoshou_Name: '销售方',
    goumaifang_Number: '购买方',
    goumaifang_Name: '购买方',
    fapiaoTime: '开票时间',
    HuoPinTitle: '货品简称',
    HuoPinCode: '货品简码',
    HuoPinCount: '数量',
    HuoPinDanJia: '单价',
    HuoPinJinE: '金额',
    ShuiLv: '税率',
    ShuiE: '税额',
    JiaShuiHeJi: '价税合计',
  }
};

//分页列表页面
export const FaPiaoLogList = () => {
  const redirect = useRedirect();
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  // 筛选器模块
  // const Filters = () => {
  //   return (
  //     <Filter>
  //       <TextInput label="ID" source="id" alwaysOn />
  //     </Filter>
  //   );
  // };
  // const MobileFilters = [
  //   <TextInput label="关键词" source="Key" alwaysOn resettable />
  // ];
  const Filters = (props: any) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, refetch } = useListContext();
    // if (isSmall) return null;
    if (props.context === "button") return null;
    const onSubmit = (values: any) => { if (Object.keys(values).length > 0) { setFilters(values, displayedFilters); } else { setFilters({}, displayedFilters); } refetch(); };
    const resetFilter = () => { setFilters({}, displayedFilters); };
    return (
      <div>
        <Form onSubmit={onSubmit} defaultValues={filterValues}>
          <Stack direction={'row'} alignItems={'baseline'} spacing={1.5}>
            <TextInput label="关键词" source="Key" alwaysOn resettable />
            <MButton variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}>搜索</MButton>
            <MButton variant="outlined" onClick={resetFilter} startIcon={<ClearAllIcon />}>重置</MButton>
          </Stack>
        </Form>
      </div>
    );
  };

  //批量操作模块
  const PostBulkActionButtons = () => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序"  /> */}
        {/* <BulkDeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" mutationMode='undoable' /> */}
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = () => {
    return (
      <div style={{ textAlign: 'right' }}>
        {/* <EditButton /><br /> */}
        <ShowButton /><br />
        {/* <DeleteWithConfirmButton confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const { classes } = useStyles();
  return (
    <List
      title={useTxtData.page.tableName + '列表'}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<Filters />}
      exporter={false}
      sx={{ "& .MuiToolbar-root .MuiToolbar-root": { height: 0, minHeight: 0 } }}
    >
      <Datagrid
        // bulkActionButtons={<PostBulkActionButtons />}
        bulkActionButtons={false}
      >
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" sortable={false} />
        <TextField label={useTxtData.table.fapiaoTime} source="fapiaoTime" />
        {/* <TextField label={useTxtData.table.sign} source="sign" /> */}

        <FunctionField label={useTxtData.table.fapiaoCode} render={() => <>
          <TextField label={useTxtData.table.shudianHaoMa} source="shudianHaoMa" />
          <br />
          <TextField label={useTxtData.table.fapiaoCode} source="fapiaoCode" />
          <br />
          <TextField label={useTxtData.table.fapiaoHaoMa} source="fapiaoHaoMa" />
        </>} />
        <FunctionField label={useTxtData.table.xiaoshou_Number} render={() => <>
          <TextField label={useTxtData.table.xiaoshou_Number} source="xiaoshou_Number" />
          <br />
          <TextField label={useTxtData.table.xiaoshou_Name} source="xiaoshou_Name" />
        </>} />
        <FunctionField label={useTxtData.table.goumaifang_Number} render={() => <>
          <TextField label={useTxtData.table.goumaifang_Number} source="goumaifang_Number" />
          <br />
          <TextField label={useTxtData.table.goumaifang_Name} source="goumaifang_Name" />
        </>} />

        <FunctionField label={useTxtData.table.goumaifang_Number} render={() => <>
          <TextField label={useTxtData.table.HuoPinTitle} source="HuoPinTitle" />
          <br />
          <TextField label={useTxtData.table.HuoPinCode} source="HuoPinCode" />
        </>} />

        <TextField label={useTxtData.table.HuoPinCount} sx={{ minWidth: 50, display: 'block' }} source="HuoPinCount" />

        <FunctionField label={useTxtData.table.HuoPinDanJia} sx={{ minWidth: 100, display: 'block' }} render={({ HuoPinDanJia, ShuiLv }: any) => <>
          <span>含税:{`${(HuoPinDanJia + HuoPinDanJia * ShuiLv).toFixed(2)}`}</span>
          <br />
          <span>未税:{`${(HuoPinDanJia).toFixed(2)}`}</span>
        </>} />

        <FunctionField label={useTxtData.table.HuoPinJinE} sx={{ minWidth: 100, display: 'block' }} render={({ JiaShuiHeJi, HuoPinJinE }: any) => <>
          <span>含税:{`${(JiaShuiHeJi).toFixed(2)}`}</span>
          <br />
          <span>未税:{`${(HuoPinJinE).toFixed(2)}`}</span>
        </>} />

        <FunctionField label={useTxtData.table.ShuiLv} sx={{ minWidth: 50, display: 'block' }} render={() => <>
          <TextField label={useTxtData.table.ShuiE} source="ShuiE" />
          <br />
          <TextField label={useTxtData.table.ShuiLv} source="ShuiLv" />
        </>} />
        {/* <TextField label={useTxtData.table.JiaShuiHeJi} source="JiaShuiHeJi" /> */}
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const FaPiaoLogShow = () => {
  const ShowActions = () => {
    const resource = useResourceContext();
    const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
        <EditButton />
      </TopToolbar>
    );
  };

  const { classes } = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.sign} source="sign" />
        <TextField label={useTxtData.table.fapiaoCode} source="fapiaoCode" />
        <TextField label={useTxtData.table.fapiaoHaoMa} source="fapiaoHaoMa" />
        <TextField label={useTxtData.table.shudianHaoMa} source="shudianHaoMa" />
        <TextField label={useTxtData.table.xiaoshou_Number} source="xiaoshou_Number" />
        <TextField label={useTxtData.table.xiaoshou_Name} source="xiaoshou_Name" />
        <TextField label={useTxtData.table.goumaifang_Number} source="goumaifang_Number" />
        <TextField label={useTxtData.table.goumaifang_Name} source="goumaifang_Name" />
        <TextField label={useTxtData.table.fapiaoTime} source="fapiaoTime" />
        <TextField label={useTxtData.table.HuoPinTitle} source="HuoPinTitle" />
        <TextField label={useTxtData.table.HuoPinCode} source="HuoPinCode" />
        <TextField label={useTxtData.table.HuoPinCount} source="HuoPinCount" />
        <TextField label={useTxtData.table.HuoPinDanJia} source="HuoPinDanJia" />
        <TextField label={useTxtData.table.HuoPinJinE} source="HuoPinJinE" />
        <TextField label={useTxtData.table.ShuiLv} source="ShuiLv" />
        <TextField label={useTxtData.table.ShuiE} source="ShuiE" />
        <TextField label={useTxtData.table.JiaShuiHeJi} source="JiaShuiHeJi" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const FaPiaoLogEdit = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();

  const PostEditActions = () => {
    return (
      <TopToolbar >
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text">
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = () => {
    return (
      <Toolbar >
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit redirect={`/${resource}`} title={'编辑' + useTxtData.page.tableName} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const FaPiaoLogCreate = () => {
  const resource = useResourceContext();
  const redirect = useRedirect();
  const notify = useNotify();

  const PostCreateActions = () => {
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(`/${resource}`)} variant="text" >
          <ArrowBackIcon />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = () => {
    const { reset, resetField, handleSubmit, setValue, getValues, } = useFormContext();
    return (
      <Toolbar>
        <SaveButton alwaysEnable label="保存&列表" />
        <SaveButton alwaysEnable type='button' label="保存&继续" variant="text" mutationOptions={{
          onSuccess: () => {
            notify('创建成功', { type: 'success' });
            reset();
          }
        }} />
      </Toolbar>
    );
  };

  return (
    <Create redirect={`/${resource}`} title={'新增' + useTxtData.page.tableName} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props: any) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      <TextInput label={useTxtData.table.id} source="id" validate={[required()]} />
      <TextInput label={useTxtData.table.sign} source="sign" validate={[required()]} />
      <TextInput label={useTxtData.table.fapiaoCode} source="fapiaoCode" validate={[required()]} />
      <TextInput label={useTxtData.table.fapiaoHaoMa} source="fapiaoHaoMa" validate={[required()]} />
      <TextInput label={useTxtData.table.shudianHaoMa} source="shudianHaoMa" validate={[required()]} />
      <TextInput label={useTxtData.table.xiaoshou_Number} source="xiaoshou_Number" validate={[required()]} />
      <TextInput label={useTxtData.table.xiaoshou_Name} source="xiaoshou_Name" validate={[required()]} />
      <TextInput label={useTxtData.table.goumaifang_Number} source="goumaifang_Number" validate={[required()]} />
      <TextInput label={useTxtData.table.goumaifang_Name} source="goumaifang_Name" validate={[required()]} />
      <TextInput label={useTxtData.table.fapiaoTime} source="fapiaoTime" validate={[required()]} />
      <TextInput label={useTxtData.table.HuoPinTitle} source="HuoPinTitle" validate={[required()]} />
      <TextInput label={useTxtData.table.HuoPinCode} source="HuoPinCode" validate={[required()]} />
      <TextInput label={useTxtData.table.HuoPinCount} source="HuoPinCount" validate={[required()]} />
      <TextInput label={useTxtData.table.HuoPinDanJia} source="HuoPinDanJia" validate={[required()]} />
      <TextInput label={useTxtData.table.HuoPinJinE} source="HuoPinJinE" validate={[required()]} />
      <TextInput label={useTxtData.table.ShuiLv} source="ShuiLv" validate={[required()]} />
      <TextInput label={useTxtData.table.ShuiE} source="ShuiE" validate={[required()]} />
      <TextInput label={useTxtData.table.JiaShuiHeJi} source="JiaShuiHeJi" validate={[required()]} />
    </SimpleForm>
  );
}